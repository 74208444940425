import expand_collapse_arrow from "assets/images/expand-collapse-arrow.svg";
import { loadCharts } from "components/FootballStats/apexChart";
import { footballStatsExpandInfo } from "components/FootballStats/FootballStats.Script";
import Image from "components/Image";
import { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { stats_key } from "./constants";
import { AppearancesExpandInfo, CoachedMinutesExpandInfo } from "./ExpandInfo";
import MiscAttributes from "components/MiscAttributes";
import modeCtrGladman from "crypto-js/mode-ctr-gladman";
import CTRGladman from "crypto-js/mode-ctr-gladman";

const FootballStats = () => {
  let { user } = useSelector((state) => state.user);
  const [showStats, setShowStats] = useState(false);

  // coachingProgramme Sum
  const coachingProgramme = user?.coached_minutes_overall?.map((data) => {
    let seasonObj = user?.coached_minutes_this_season?.find((item) => {
      return data?.title.trim() === item?.title;
    });

    let coachingProgrammeData = {
      title: data?.title,
      seasonToDate: seasonObj?.value
        ? seasonObj?.value
        : seasonObj?.defaultValue,
      career: data?.value ? data?.value : data?.defaultValue,
    };
    return coachingProgrammeData;
  });

  let coachingProgrammeSeasonTotal = 0;
  let coachingProgrammeOverAllTotal = 0;
  coachingProgramme?.map((data) => {
    coachingProgrammeSeasonTotal += Number(data.seasonToDate);
    coachingProgrammeOverAllTotal += Number(data.career);
    return data;
  });

  // gamesProgramme Sum
  const gamesProgramme_ = user?.game_time_overall?.map((data) => {
    let seasonObj = user?.game_time_season?.find((item) => {
      return data.title.trim() === item?.title;
    });

    let gamesProgrammeData = {
      title: data?.title,
      seasonToDate: seasonObj?.value
        ? seasonObj?.value
        : seasonObj?.defaultValue,
      career: data?.value ? data?.value : data?.defaultValue,
    };
    return gamesProgrammeData;
  });

  let gamesProgramme_SeasonTotal = 0;
  let gamesProgramme_OverAllTotal = 0;
  gamesProgramme_?.map((data) => {
    gamesProgramme_SeasonTotal += Number(data.seasonToDate);
    gamesProgramme_OverAllTotal += Number(data.career);
    return data;
  });

  // Appearances sum
  let appearances_seasonTotal =
    +user.goals_this_season + +user.assists_this_season;

  let appearances_overAllTotal = +user.goals_overall + +user.assists_overall;

  // eslint-disable-next-line
  useLayoutEffect(() => {
    setTimeout(() => {
      loadCharts({
        appearances: {
          season: appearances_seasonTotal,
          overAll: appearances_overAllTotal,
        },
        coachedMinutes: {
          season: coachingProgrammeSeasonTotal,
          overAll: coachingProgrammeOverAllTotal,
        },
        gamesProgramme_Minutes: {
          season: gamesProgramme_SeasonTotal,
          overAll: gamesProgramme_OverAllTotal,
        },
      });
    }, 2000);
    // eslint-disable-next-lines
  }, [user]);

  useEffect(() => {
    footballStatsExpandInfo();
  }, [user]);

  useEffect(() => {
    const checkStatsData = () => {
      const { appearances, coachedMinutes, gamesProgramme_Minutes } = {
        appearances: {
          season: appearances_seasonTotal,
          overAll: appearances_overAllTotal,
        },
        coachedMinutes: {
          season: coachingProgrammeSeasonTotal,
          overAll: coachingProgrammeOverAllTotal,
        },
        gamesProgramme_Minutes: {
          season: gamesProgramme_SeasonTotal,
          overAll: gamesProgramme_OverAllTotal,
        },
      };

      const hasNonZeroValue = Object.values(appearances)
        .concat(Object.values(coachedMinutes))
        .concat(Object.values(gamesProgramme_Minutes))
        .every((value) => value > 0);

      setShowStats(hasNonZeroValue);
    };

    checkStatsData();
  }, [user]);

  // const footballStatsData = [
  //   // {
  //   //   outerDivId: "appearances",
  //   //   innerDivId: "appearance-chart",
  //   //   name: "Appearances",
  //   //   chartOptions: optionsAppearances,
  //   // },
  //   {
  //     outerDivId: "minutes-played",
  //     innerDivId: "minutes-played-chart",
  //     name: "Minutes Played",
  //     chartOptions: optionsMinutes,
  //   },
  // ];

  // const coachedMinutesData = [
  //   {
  //     name: "Coached Minutes Played",
  //     chartOptions: optionscoachMinutes,
  //   },
  // ];

  if (!showStats) {
    return null;
  }

  return (
    <div
      id="football-stats"
      className="flex-fill d-flex flex-row gut-100 section"
    >
      <div className="col d-flex flex-column align-items-start justify-content-center content-box">
        <h2 id="previous-clubs-title" className="text-right">
          My <span className="font-weight-bold">Football Stats</span>
        </h2>
        <div
          id="stats-key"
          className="col-md-4 d-flex flex-row gap-50 p-0 flex-unset"
        >
          {stats_key?.map((data) => (
            <div className="col-6 p-0 d-flex flex-row align-items-center gap-10">
              <div className={`key ${data?.class}`}></div>
              <p>{data?.name}</p>
            </div>
          ))}
        </div>
        <div className="main-stats d-flex gap-50 flex-column w-100">
          <h5 className="player-position">Central Defensive Midfielder</h5>
          <div className="row d-flex flex-column w-100 flex-nowrap stat-info-section">
            <div className="row d-flex flex-row gap-50 w-100 flex-nowrap football-stats-charts">
              <div className="d-flex flex-column gap-10 col-md-8 p-0 chart-row flex-unset">
                <div className="d-flex flex-row stat-row">
                  <div
                    id="appearances"
                    className="col-md-6 p-0 d-flex flex-column flex-unset stat-item"
                  >
                    <p className="font-weight-bold">Appearances</p>
                    <div id="appearance-chart" className="w-100"></div>
                  </div>
                  <div
                    id="minutes-played"
                    className="col-md-6 p-0 d-flex flex-column flex-unset stat-item"
                  >
                    <p className="font-weight-bold">Minutes Played</p>
                    <div id="minutes-played-chart" className="w-100"></div>
                  </div>
                  {/* {footballStatsData?.map((data) => (
                    <div
                      // id="appearances"
                      id={data?.outerDivId}
                      className="col-md-6 p-0 d-flex flex-column flex-unset stat-item"
                    >
                      <p className="font-weight-bold">{data?.name}</p>
                      <div id={data.innerDivId} className="w-100">
                        <Chart
                          options={data?.chartOptions?.options}
                          series={data?.chartOptions?.series}
                          type="bar"
                          height={160}
                        />
                      </div>
                    </div>
                  ))} */}
                </div>
                <div
                  id="app-min-row"
                  className="col-12 p-0 d-flex flex-column flex-unset"
                >
                  <div
                    id="app-min-expand"
                    className="expand-collapse-row-football-stats w-100 d-flex flex-column cursor-pointer"
                  >
                    <div className="row d-flex flex-row align-items-center justify-content-end">
                      <div className="col-auto d-flex justify-content-center">
                        <p className="green expand-collapse-text">
                          Expand Info
                        </p>
                      </div>
                      <div className="col-auto d-flex justify-content-center p-0">
                        <Image className="rotate" src={expand_collapse_arrow} />
                      </div>
                    </div>
                    <div className="col-12 expand-collapse-seperator d-flex flex-unset"></div>
                  </div>

                  <AppearancesExpandInfo />
                </div>
              </div>

              <div className="d-flex flex-column gap-10 col-md-4 p-0 chart-row flex-unset">
                <div className="d-flex flex-row stat-row">
                  <div
                    id="coached-minutes-played"
                    className="col-md-12 p-0 d-flex flex-column flex-unset stat-item"
                  >
                    <p className="font-weight-bold">Coached Minutes</p>
                    <div id="coached-minutes-played-chart" className="w-100">
                      {/* {coachedMinutesData?.map((data) => (
                        <Chart
                          options={data?.chartOptions?.options}
                          series={data?.chartOptions?.series}
                          type="bar"
                          height={160}
                        />
                      ))} */}
                    </div>
                  </div>
                </div>
                <div
                  id="coach-min-row"
                  className="col-12 p-0 d-flex flex-column flex-unset"
                >
                  <div
                    id="coach-min-expand"
                    className="expand-collapse-row-football-stats w-100 d-flex flex-column cursor-pointer"
                  >
                    <div className="row d-flex flex-row align-items-center justify-content-end">
                      <div className="col-auto d-flex justify-content-center">
                        <p className="green expand-collapse-text">
                          Expand Info
                        </p>
                      </div>
                      <div className="col-auto d-flex justify-content-center p-0">
                        <Image className="rotate" src={expand_collapse_arrow} />
                      </div>
                    </div>
                    <div className="col-12 expand-collapse-seperator d-flex flex-unset"></div>
                  </div>
                  <CoachedMinutesExpandInfo />
                </div>
              </div>
            </div>
          </div>
        </div>

        <MiscAttributes Category="football stats" />
      </div>
    </div>
  );
};

export default FootballStats;
